// atoms.ts
import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';

import { ScriptInfo } from './types';

export const scriptInfoAtom = atom<ScriptInfo | null>(null);
export const webCallAtom = selectAtom(
  scriptInfoAtom,
  (scriptInfo) => !!scriptInfo?.webCall
);
export const scheduleFollowUpAtom = selectAtom(
  scriptInfoAtom,
  (scriptInfo) => !!scriptInfo?.scheduleFollowUp
);
