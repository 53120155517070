import React, { useState, useEffect } from 'react';
import './AudioPlayer.css';
import env from 'env';
import axios from 'api/axiosConfig';
import { Loader } from '@mantine/core';

const VideoPlayer = ({ callId, seekTimestamp, webAudio }) => {
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [videoUrl, setVideoUrl] = useState('');
  const [isRenderingVideo, setIsRenderingVideo] = useState(true);
  const videoRef = React.useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (seekTimestamp !== null && videoRef.current) {
      console.log('setting play pos to seek timestamp', seekTimestamp);
      videoRef.current.currentTime = seekTimestamp;
      videoRef.current.play();
    }
  }, [seekTimestamp]);

  useEffect(() => {
    let pollingInterval;

    const checkPhoneAvailability = async () => {
      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/check_phone_recording/${callId}`
        );
        if (response.status === 200 && response.data.available) {
          setIsVideoAvailable(true);
          setVideoUrl(response.data.video_url);
          setIsLoading(false);
          clearInterval(pollingInterval); // Stop polling when video is available
        }
      } catch (error) {
        console.log('Video not available yet. Retrying...');
      }
    };

    const checkWebAudioAvailability = async () => {
      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/check_web_audio_recording/${callId}`
        );
        if (response.status === 200 && response.data.available) {
          setIsVideoAvailable(true);
          setVideoUrl(response.data.video_url);
          setIsLoading(false);
          clearInterval(pollingInterval); // Stop polling when video is available
        }
      } catch (error) {
        console.log('Video not available yet. Retrying...');
      }
    };

    // Start polling
    pollingInterval = setInterval(checkPhoneAvailability, 3000);
    if (webAudio) {
      checkWebAudioAvailability();
    } else {
      checkPhoneAvailability();
    }

    // Clean up polling on component unmount
    return () => clearInterval(pollingInterval);
  }, [callId, webAudio]);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.addEventListener('loadeddata', () => {
        setIsRenderingVideo(false); // Hide loader when video is ready
      });
    }

    return () => {
      if (video) {
        video.removeEventListener('loadeddata', () => {
          setIsRenderingVideo(false);
        });
      }
    };
  }, [isVideoAvailable]); // Run when video becomes available

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
      }}
    >
      {(isLoading || isRenderingVideo) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            position: 'relative',
            width: '60%',
            flexDirection: 'column',
            borderRadius: '16px',
            border: '1px solid var(--salv-dark-2)',
            aspectRatio: 3.5,
            // height: '300px',
            opacity: '.7',
          }}
        >
          <h4 style={{ marginBottom: '4px' }}>
            {webAudio ? 'Loading Call Audio' : 'Loading Phone Call'}
          </h4>
          <Loader type='dots' size='sm' />
        </div>
      )}
      {isVideoAvailable && (
        <video
          controls
          ref={videoRef}
          style={{
            borderRadius: '16px',
            width: '80%',
            position: 'relative',
            height: '60px',
            marginBottom: '6px',
            display: isRenderingVideo ? 'none' : 'block',
            // border: '4px solid var(--salv-dark-1)',
          }}
        >
          <source src={videoUrl} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

const PhoneCallRecordingPlayer = ({ callId, seekTimestamp, webAudio }) => {
  return (
    <div style={{ display: 'flex', fontSize: '14px' }}>
      {!!callId && (
        <VideoPlayer
          callId={callId}
          seekTimestamp={seekTimestamp}
          webAudio={webAudio}
        />
      )}
    </div>
  );
};

export default PhoneCallRecordingPlayer;
