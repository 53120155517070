import React, { useState } from 'react';
import {
  Box,
  ScrollArea,
  Text,
  Group,
  RingProgress,
  Tabs,
} from '@mantine/core';
import { formatToLocalTime } from 'utils/dateUtils';
import { convertUpperSnakeToTitle } from 'utils/formatUtils';
import { RequirementImportance } from 'pages/editorv2/types';

import WebCallRecordingPlayer from './WebCallRecordingPlayer';
import PhoneCallRecordingPlayer from './PhoneCallRecordingPlayerV2';
import './Transcript.css';
import EmailHistory, { Email } from './EmailHistory';

const TabOptions = {
  CALL: 'Call',
  SMS: 'SMS',
  EMAIL: 'Email',
} as const;

type Tab = (typeof TabOptions)[keyof typeof TabOptions];
const isTab = (value: any): value is Tab => {
  return Object.values(TabOptions).includes(value);
};

interface TranscriptProps {
  transcript: string;
  requirementGradesList: {
    question: string;
    answer: string;
    grade: string;
    importance: RequirementImportance;
    ts: number;
    title: string;
  }[];
  callId: string;
  smsTranscript?: string;
  overallGrade?: number;
  completionRate?: number;
  candidateName?: string;
  lastCalled?: string;
  testCall?: boolean;
  allCalls?: any[];
  callComplete?: boolean;
  emails?: Email[];
  emailsLoading?: boolean;
  webCall?: boolean;
}

const parseSmsTranscript = (smsTranscript: string | undefined) => {
  if (!smsTranscript) return [];
  console.log('sms transcript', smsTranscript);
  if (typeof smsTranscript !== 'string') {
    console.error('Invalid type for text:', typeof smsTranscript);
    return [];
  }
  return smsTranscript
    .split('\n')
    .map((line) => {
      const [timestampStr, rest] = line.split('|');
      if (!timestampStr || !rest) {
        return null;
      }

      const timestamp = new Date(timestampStr);
      const [role, ...contentParts] = rest.split(':');
      return {
        start_ts: timestamp.getTime(),
        role: role.trim(),
        content: contentParts.join(':').trim(),
      };
    })
    .filter(Boolean);
};

const BoldTranscript = ({ transcript, sms, onTimestampClick, clickable }) => {
  const escapeAngleBrackets = (text) =>
    text.replace(/</g, '&lt;').replace(/>/g, '&gt;');

  const highlightGrayText = (text) => {
    let updatedText = escapeAngleBrackets(text);
    updatedText = updatedText.replace(
      /(&lt;.*?&gt;|~(.*?)~)/g,
      '<span style="color:gray">$1</span>'
    );
    return updatedText;
  };

  const formatTimestampSMS = (timestampMs: number) => {
    const date = new Date(timestampMs);
    return date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const formatTimestamp = (timestamp) => {
    if (timestamp === null || timestamp === undefined) {
      return null;
    }
    const minutes = Math.floor(timestamp / 60);
    const seconds = Math.floor(timestamp % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  // Process the transcript text into structured elements
  const processTranscript = (transcript) => {
    if (!Array.isArray(transcript)) {
      console.error('Invalid transcript data:', transcript);
      return <Text>No transcript available</Text>; // Fallback for invalid data
    }

    // Function to render a message block
    const renderMessageBlock = (message, index) => (
      <Group
        key={index}
        style={{
          marginTop: index === 0 ? 0 : 12,
          alignItems: 'flex-start',
          width: '100%',
        }}
        onClick={() => onTimestampClick(message.start_ts)}
        className={
          clickable && (message.start_ts || index === 0) ? 'clickable' : ''
        }
      >
        <div style={{ marginLeft: 0, width: '100%' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: sms ? 'space-between' : 'flex-start',
              width: '100%',
            }}
          >
            {!sms && message.start_ts !== null && (
              <Text size='xs' style={{ color: 'gray', marginRight: 8 }}>
                {formatTimestamp(message.start_ts)}
              </Text>
            )}
            <Text
              size='sm'
              fw={700}
              style={{
                color:
                  message.role === 'Candidate'
                    ? 'var(--mantine-color-blue-9)'
                    : '#222324',
              }}
            >
              {message.role}
            </Text>
            {sms && message.start_ts !== null && (
              <Text size='xs' style={{ color: 'gray', marginRight: 8 }}>
                {formatTimestampSMS(message.start_ts)}
              </Text>
            )}
          </div>
          <Text
            style={{ paddingLeft: '4px', fontSize: '14px' }}
            dangerouslySetInnerHTML={{
              __html: highlightGrayText(message.content.trim()),
            }}
          />
        </div>
      </Group>
    );

    // Iterate over the transcript array and render each message
    return transcript.map((message, index) =>
      renderMessageBlock(message, index)
    );
  };

  // Render the processed transcript
  return <div>{processTranscript(transcript)}</div>;
};

const Transcript: React.FC<TranscriptProps> = ({
  transcript,
  requirementGradesList,
  callId,
  smsTranscript,
  overallGrade,
  completionRate,
  candidateName,
  lastCalled,
  testCall,
  allCalls,
  callComplete,
  emails,
  emailsLoading,
  webCall,
}) => {
  const [activeTab, setActiveTab] = useState<Tab>(TabOptions.CALL);
  const parsedSmsTranscript = parseSmsTranscript(smsTranscript);
  const email = localStorage.getItem('email') || '';
  const isInternal = email.includes('salv.ai');
  const renderResultsColumn = () => {
    if (!callId) {
      return (
        <div className='qa-column'>
          <h4
            className='candidate-name'
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            {candidateName}
          </h4>
          <h4 style={{ margin: 'auto' }}> No Screening Results</h4>
        </div>
      );
    }
    return (
      <div className='qa-column'>
        <h4
          className='candidate-name'
          style={{ marginTop: 0, marginBottom: 0 }}
        >
          {candidateName}
        </h4>
        <div
          style={{
            display: 'flex',
            gap: '15%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <RingProgress
              size={65}
              thickness={6}
              roundCaps
              sections={[
                {
                  value: (completionRate || 0) * 100,
                  color: 'var(--mantine-color-blue-4)',
                },
              ]}
              label={
                <Text c='blue' fw={700} ta='center' size='xs'>
                  {`${((completionRate || 0) * 100).toFixed(0)}%`}
                </Text>
              }
            />
            <div style={{ fontWeight: '500' }}>Completion Rate</div>
          </div>
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <RingProgress
              size={65}
              thickness={6}
              roundCaps
              sections={[
                {
                  value: overallGrade || 0,
                  color: 'var(--mantine-color-blue-4)',
                },
              ]}
              label={
                <Text c='blue' fw={700} ta='center' size='xs'>
                  {`${(overallGrade || 0).toFixed(0)}%`}
                </Text>
              }
            />
            <div style={{ fontWeight: '500' }}>Grade</div>
          </div>
        </div>
        <ScrollArea className='scrollable-content'>
          <div className='scroll-fade-top'></div>
          <div className='inside'>
            {requirementGradesList.map(
              ({ question, answer, grade, ts, title }, index) => (
                <div
                  key={index}
                  className={'qa-item' + (ts ? ' clickable' : '')}
                  onClick={() => {
                    if (ts) {
                      console.log('seeking to', ts);
                      onTimestampClick(ts);
                    }
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div className='grade'>{grade}</div>
                    <div className='question'>{title ? title : question}</div>
                  </div>
                  <div className='answer'>{answer}</div>
                </div>
              )
            )}
          </div>
          <div className='scroll-fade-bottom'></div>
        </ScrollArea>
      </div>
    );
  };

  const [seekTimestamp, setSeekTimestamp] = useState<number | null>(null);

  const onTimestampClick = (timestamp) => {
    setSeekTimestamp(timestamp);
  };

  return (
    <Box className='transcript-container'>
      <Tabs
        variant='pills'
        value={activeTab}
        onChange={(value) => {
          if (isTab(value)) {
            setActiveTab(value);
          }
        }}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          zIndex: 10001,
        }}
      >
        <div
          style={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '6px',
            }}
          >
            <Tabs.List>
              {/* <h3 style={{ margin: 0, marginRight: '20px' }}>Communication</h3> */}
              <Tabs.Tab
                value={TabOptions.CALL}
                classNames={{
                  tab: 'header-tab',
                }}
              >
                {!webCall ? 'Phone Call' : 'Video Call'}
              </Tabs.Tab>
              {!testCall && !webCall && (
                <Tabs.Tab
                  value={TabOptions.SMS}
                  classNames={{
                    tab: 'header-tab',
                  }}
                >
                  SMS
                </Tabs.Tab>
              )}
              {isInternal && !webCall && (
                <Tabs.Tab
                  value={TabOptions.EMAIL}
                  classNames={{
                    tab: 'header-tab',
                  }}
                >
                  Email
                </Tabs.Tab>
              )}
            </Tabs.List>
          </div>

          <Tabs.Panel
            value={TabOptions.SMS}
            style={{ height: 'calc(100% - 90px)', padding: 0, margin: 0 }}
          >
            {parsedSmsTranscript.length > 0 ? (
              <div className='transcript-column'>
                <ScrollArea className='scrollable-content'>
                  <div className='scroll-fade-top'></div>
                  <div className='inside'>
                    <BoldTranscript
                      transcript={parsedSmsTranscript}
                      sms={true}
                      onTimestampClick={() => {}}
                      clickable={false}
                    />
                  </div>
                  <div className='scroll-fade-bottom'></div>
                </ScrollArea>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                  height: '100%',
                  alignItems: 'center',
                }}
              >
                <h5>No SMS Transcript Available</h5>
              </div>
            )}
          </Tabs.Panel>

          <Tabs.Panel
            value={TabOptions.CALL}
            style={{ height: 'calc(100% - 90px)', padding: 0, margin: 0 }}
          >
            {!allCalls?.length && !testCall && (
              <div className='transcript-column'>
                <h4 style={{ margin: 'auto' }}> No Call Results</h4>
              </div>
            )}
            {!!allCalls?.length && !callComplete && !testCall && (
              <div className='transcript-column'>
                <h3>Call History</h3>
                <div>
                  {allCalls.map((c) => (
                    <div
                      style={{
                        display: 'flex',
                        padding: '14px',
                        gap: '8px',
                        fontSize: '14px',
                        justifyContent: 'space-between',
                        marginRight: '25%',
                        border: '1px solid var(--salv-dark-1)',
                        borderRadius: '8px',
                        marginBottom: '10px',
                      }}
                    >
                      <div style={{ fontWeight: '600', fontSize: '16px' }}>
                        <div>{convertUpperSnakeToTitle(c.call_status)}</div>
                      </div>
                      <div style={{ opacity: 0.7 }}>
                        <div>{formatToLocalTime(c.last_updated, true)}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!!callId && callComplete && !webCall && (
              <div>
                {lastCalled && (
                  <div
                    style={{
                      padding: '8px',
                      fontSize: '12px',
                      marginTop: '6px',
                    }}
                  >
                    {`Call from ${formatToLocalTime(lastCalled, true)}`}
                  </div>
                )}
                <PhoneCallRecordingPlayer
                  callId={callId}
                  seekTimestamp={seekTimestamp}
                  webAudio={false}
                />
                {/* <CallRecordingPlayer callId={callId} /> */}
              </div>
            )}

            {callComplete && (
              <div className='transcript-column phone-transcript-column'>
                {/* <h3 style={{ marginTop: '4px', marginBottom: '4px' }}>Contact</h3> */}
                <ScrollArea className='scrollable-content'>
                  {!!callId && callComplete && webCall && (
                    <div>
                      {lastCalled && (
                        <div
                          style={{
                            padding: '8px',
                            fontSize: '12px',
                            marginTop: '6px',
                          }}
                        >
                          {`Call from ${formatToLocalTime(lastCalled, true)}`}
                        </div>
                      )}
                      <WebCallRecordingPlayer
                        callId={callId}
                        seekTimestamp={seekTimestamp}
                        testCall={testCall}
                      />
                    </div>
                  )}
                  <div className='inside transcript-box'>
                    {/* <Text>{transcript}</Text> */}
                    <BoldTranscript
                      transcript={transcript}
                      sms={false}
                      onTimestampClick={onTimestampClick}
                      clickable={true}
                    />
                  </div>
                  <div className='scroll-fade-bottom'></div>
                </ScrollArea>
              </div>
            )}
          </Tabs.Panel>
          <Tabs.Panel
            value={TabOptions.EMAIL}
            style={{ height: 'calc(100% - 90px)', padding: 0, margin: 0 }}
          >
            <EmailHistory emails={emails} loading={emailsLoading} />
          </Tabs.Panel>
        </div>
      </Tabs>
      {/* <Divider orientation='vertical' /> */}
      {renderResultsColumn()}
    </Box>
  );
};

export default Transcript;
