import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Table,
  Title,
  Modal,
  Center,
  Loader,
  Badge,
  ActionIcon,
  Skeleton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { debounce } from 'lodash';
import env from 'env';
import { IconRefresh } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import SortableHeader from 'components/common/SortableHeader';
import { RequirementImportance } from 'pages/editorv2/types';
import { convertUpperSnakeToTitle } from 'utils/formatUtils';
import { formatPhoneNumber } from 'utils/phoneUtils';
import { useAtomValue } from 'jotai';
import { scheduleFollowUpAtom } from 'pages/editorv2/atoms';
import { v4 as uuidv4 } from 'uuid';

import CampaignDetailStatsRow from './CampaignDetailStatsRow';
import axios from '../../../api/axiosConfig';
import Transcript from '../../../components/transcript/Transcript';
import './CampaignDetailsPage.css';
import {
  convertSecondsToMinutesAndSeconds,
  formatToLocalTime,
} from '../../../utils/dateUtils';

import 'index.css';

const CallStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  CANCELLED: 'CANCELLED',
  VOICEMAIL: 'VOICEMAIL',
  NO_ANSWER: 'NO_ANSWER',
  INVALID_NUMBER: 'INVALID_NUMBER',
  INVITED: 'INVITED',
};

const CallInitiatedBy = {
  CAMPAIGN: 'CAMPAIGN',
  AUTOMATIC_RETRY: 'AUTOMATIC_RETRY',
  CANDIDATE_RESCHEDULED: 'CANDIDATE_RESCHEDULED',
  INBOUND: 'INBOUND',
};

const SortColumn = {
  LAST_UPDATED: 'last_updated',
  OVERALL_GRADE: 'overall_grade',
  COMPLETION_RATE: 'completion_rate',
  CALL_LENGTH_SEC: 'call_length_sec',
};

interface SortConfig {
  column: string;
  direction: 'ascending' | 'descending';
}

const COLLAPSE_THRESHOLD = 1400; // Note: threshold here AND css file

const PAGINATION_AMOUNT = 50;

const CampaignDetailsPage = ({ isWebCall }) => {
  const [sort, setSort] = useState<SortConfig | undefined>({
    column: SortColumn.COMPLETION_RATE,
    direction: 'descending',
  });
  const handleSort = (column: string) => () => {
    if (sort && sort.column === column) {
      if (sort.direction === 'descending')
        setSort({ column, direction: 'ascending' });
      else setSort(undefined);
    } else {
      setSort({ column, direction: 'descending' });
    }
  };

  const scheduleFollowUp = useAtomValue(scheduleFollowUpAtom); // Subscribes to atom updates

  const [isCollapsed, setIsCollapsed] = useState(
    window.innerWidth <= COLLAPSE_THRESHOLD
  );

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth <= COLLAPSE_THRESHOLD);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on window width

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [totalCount, setTotalCount] = useState(0);
  const [isFetchingCalls, setIsFetchingCalls] = useState(true);
  const [isFetchingTranscript, setIsFetchingTranscript] = useState(false);
  const [candidates, setCandidates] = useState<any>([]);
  const [transcriptError, setTranscriptError] = useState(false);
  const [meetingTimes, setMeetingTimes] = useState<
    Record<string, string | null>
  >({});
  const [transcriptData, setTranscriptData] = useState({
    transcript: '',
    requirementGradesList: [] as {
      question: string;
      answer: string;
      grade: string;
      importance: RequirementImportance;
      ts: number;
      title: string;
    }[],
    smsTranscript: '',
    callId: '',
    overallGrade: 0,
    questionCompletionRate: 0,
    candidateName: '',
    lastCalled: '',
    allCalls: [],
    callComplete: false,
    emails: [],
    webCall: false,
  });

  const [transcriptOpened, { open: openTranscript, close: closeTranscript }] =
    useDisclosure(false);

  const location = useLocation();
  const match = location.pathname.match(
    /\/scripts\/script-editor\/(new(?:-phone|-web)?\/)?([^/]+)/
  );
  const isNew = !!match?.[1]; // 'new/' part exists if match[1] is truthy

  const { campaignId: paramCampaignId } = useParams();
  const campaignId = match ? match[2] : paramCampaignId || '';

  const fetchLockRef = useRef<string | null>(null);

  const fetchCandidates = useCallback(
    async ({ skip, limit, silent = false }) => {
      if (!campaignId) return;
      if (fetchLockRef.current && silent) {
        // don't perform silent fetches if active fetch in progress
        return;
      }
      const refLockKey = '' + uuidv4();
      try {
        fetchLockRef.current = refLockKey;
        if (!silent) {
          setIsFetchingCalls(true);
        }
        const payload = {
          sort: {
            column: sort?.column || SortColumn.LAST_UPDATED,
            descending: sort?.direction === 'descending' || !sort?.direction,
          },
          isNew: isNew,
        };
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/campaigns/campaign/${campaignId}/candidates_and_calls`,
          payload,
          {
            params: {
              skip,
              limit,
            },
          }
        );
        if (fetchLockRef.current === refLockKey) {
          // don't set candidates if newer fetch started
          setCandidates((prev) => {
            if (silent) {
              return [...response.data.candidates];
            }
            return [...prev, ...response.data.candidates]; // Append new candidates
          });
          setTotalCount(Number(response.data['total_records']));
        }
      } catch (error) {
        console.error('Error fetching campaign calls:', error);
        if (!isNew) {
          notifications.show({
            title: 'There was an error retrieving the campaign calls',
            message: error instanceof Error ? error.message : '',
            color: 'red',
          });
        }
      } finally {
        setIsFetchingCalls(false);
        fetchLockRef.current = null;
      }
    },
    [setTotalCount, setCandidates, campaignId, sort, isNew]
  );

  const candidateIdsNeedMeetingFetched = useMemo(() => {
    return candidates
      .map((candidate) => candidate.candidate.candidate_id)
      .filter((id) => !(id in meetingTimes));
  }, [candidates, meetingTimes]);

  const fetchMeetingTimes = useCallback(
    async () => {
      if (!campaignId) return;

      try {
        // If all candidate_ids already have meeting times, do nothing
        if (candidateIdsNeedMeetingFetched.length === 0) return;

        // Fetch meeting times for missing candidate_ids
        const response = await axios.post(
          `${env.REACT_APP_SERVER_URL}/campaign/get_meeting_times/${campaignId}`,
          { candidate_ids: candidateIdsNeedMeetingFetched } // Pass candidate_ids as a payload
        );

        const fetchedMeetingTimes = response.data || {};
        // Transform the map into a dictionary (no transformation needed if it's already in the right format)
        const newMeetingTimesMap = Object.entries(fetchedMeetingTimes).reduce(
          (acc, [candidate_id, meeting_time]) => {
            acc[candidate_id] = meeting_time || null;
            return acc;
          },
          {}
        );
        console.log('newMeetingTimesMap', JSON.stringify(newMeetingTimesMap));
        // Merge new meeting times with the existing meetingTimesMap
        setMeetingTimes((prevMeetingTimesMap) => ({
          ...prevMeetingTimesMap,
          ...newMeetingTimesMap,
        }));
      } catch (error) {
        console.error('Error fetching meeting times:', error);
        notifications.show({
          title: 'Error retrieving meeting times',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
        const noMeetingTimesMap = candidateIdsNeedMeetingFetched.reduce(
          (map, id) => {
            map[id] = null; // Set the default value for each id
            return map;
          },
          {}
        );
        setMeetingTimes((prevMeetingTimesMap) => ({
          ...prevMeetingTimesMap,
          ...noMeetingTimesMap,
        }));
      }
    },
    [campaignId, candidateIdsNeedMeetingFetched] // Add dependencies
  );

  useEffect(() => {
    if (!campaignId) return;

    fetchMeetingTimes();
  }, [campaignId, fetchMeetingTimes]);

  const debouncedFetchCandidates = useMemo(
    () =>
      debounce((limit: number, skip: number, silent: boolean) => {
        if (!campaignId) return;
        if (!silent) {
          setIsFetchingCalls(true);
        }
        if (skip === 0 && !silent) {
          setCandidates([]);
          setTotalCount(0);
        }
        fetchCandidates({
          limit: limit,
          skip: skip,
          silent: silent,
        });
      }, 300), // 300ms debounce delay
    [fetchCandidates, campaignId] // Only recreate if fetchCandidates changes
  );

  useEffect(() => {
    let delay = 10_000;

    const handleInterval = () => {
      debouncedFetchCandidates(
        Math.max(candidates.length, PAGINATION_AMOUNT),
        0,
        true
      );
      if (delay < 1_000_000) {
        delay *= 2;
      }
      clearInterval(intervalId);
      intervalId = setInterval(handleInterval, delay);
    };

    let intervalId = setInterval(handleInterval, delay);

    return () => clearInterval(intervalId);
  }, [debouncedFetchCandidates, candidates.length]);

  useEffect(() => {
    if (!campaignId) return;
    setCandidates([]);
    setTotalCount(0);
    // Reset pagination when campaignId or sort changes
    debouncedFetchCandidates(PAGINATION_AMOUNT, 0);
  }, [campaignId, sort, debouncedFetchCandidates]);

  const fetchTranscriptData = async (candidateId) => {
    setIsFetchingTranscript(true);
    try {
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/call_details/${campaignId}`
      );
      setTranscriptData({
        transcript: response.data.transcript,
        requirementGradesList: response.data.requirement_grades_list,
        smsTranscript: response.data.sms_transcript,
        overallGrade: response.data.last_call?.overall_grade || 0,
        questionCompletionRate:
          response.data.last_call?.question_completion_rate || 0,
        callId: response.data.last_call?.call_id || undefined,
        candidateName: response.data.candidate.fullName,
        lastCalled: response.data.last_call?.last_updated || '',
        allCalls: response.data.all_calls || [],
        callComplete: response.data.last_call?.call_status === 'COMPLETED',
        emails: response.data.emails || [],
        webCall: response.data.last_call?.web_call,
      });

      setTranscriptError(false);
    } catch (error) {
      setTranscriptError(true);
      console.error('Error fetching transcript data:', error);
      notifications.show({
        title: 'There was an error retrieving the call details',
        message: error instanceof Error ? error.message : '',
        color: 'red',
      });
    } finally {
      setIsFetchingTranscript(false);
    }
  };

  const handleRowClick = (candidateId) => {
    openTranscript();
    fetchTranscriptData(candidateId);
  };

  const candidateToCallStatus = (candidate) => {
    const { calls } = candidate;
    if (calls && calls.length > 0) {
      const lastCall = calls[0];
      if (lastCall.call_status === CallStatus.PENDING) {
        if (calls.call_initiated_by === CallInitiatedBy.AUTOMATIC_RETRY) {
          return 'Automatic Callback Pending';
        }
        if (calls.call_initiated_by === CallInitiatedBy.CANDIDATE_RESCHEDULED) {
          return 'Callback Pending';
        }
        return 'Pending';
      }
      return convertUpperSnakeToTitle(lastCall.call_status);
    }
  };

  const candidateToSmsStatus = (candidate) => {
    return convertUpperSnakeToTitle(candidate.sms_status);
  };

  // const getLongestCall = (candidate) =>
  //   candidate.calls?.reduce(
  //     (longest, current) =>
  //       current.call_length_sec > longest.call_length_sec ? current : longest,
  //     candidate.calls[0]
  //   );

  // const isCallCompleted = (call) => call?.call_status === CallStatus.COMPLETED;

  // TODO: just return is_done on the call
  const hasCallHappened = (call) =>
    call?.call_status !== CallStatus.PENDING &&
    call?.call_status !== CallStatus.INVITED &&
    call?.call_status !== CallStatus.CANCELLED;

  const candidateToRecentCallLength = (candidate) => {
    const max_call_length_sec = candidate.max_call_length_sec;
    const numCalls = candidateToNumberOfCalls(candidate);
    return max_call_length_sec && numCalls > 0
      ? convertSecondsToMinutesAndSeconds(max_call_length_sec)
      : '-';
  };

  const candidateToCompletionRate = (candidate) => {
    const numCalls = candidateToNumberOfCalls(candidate);
    if (numCalls === 0) {
      return '-';
    }
    return `${(candidate.max_completion_rate * 100).toFixed(0)}%` || '-';
  };

  const candidateToOverallGrade = (candidate) => {
    const numCalls = candidateToNumberOfCalls(candidate);
    if (numCalls === 0) {
      return '-';
    }
    if (!candidate.max_overall_grade) {
      return '-';
    }
    return `${candidate.max_overall_grade?.toFixed(0)}%` || '-';
  };

  const candidateToLastCalled = (candidate) => {
    const numCalls = candidateToNumberOfCalls(candidate);

    return numCalls > 0
      ? formatToLocalTime(candidate.max_last_updated) || '-'
      : '-';
  };

  const candidateToNumberOfCalls = (candidate) => {
    return (
      candidate.candidate.calls.filter((c) => hasCallHappened(c)).length || 0
    );
  };

  const formatMeetingTime = (timestamp: string | null) => {
    if (!timestamp) return '-';
    return formatToLocalTime(timestamp);
  };

  return (
    <div className='campaign-details-page' style={{ marginTop: '0pt' }}>
      <CampaignDetailStatsRow campaignId={campaignId} isWebCall={isWebCall} />
      <div
        className='common-table-wrapper'
        style={{
          maxHeight: 'calc(100vh - 160px)',
          overflowY: 'scroll',
          background: 'white',
        }}
        onScroll={(e) => {
          const element = e.target as HTMLElement;

          if (
            element.scrollHeight - element.scrollTop <=
              element.clientHeight + 20 &&
            !isFetchingCalls
          ) {
            if (candidates.length >= totalCount) return;

            setIsFetchingCalls(true);
            debouncedFetchCandidates(PAGINATION_AMOUNT, candidates.length);
          }
        }}
      >
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th
                style={{
                  width: '20%',
                }}
              >
                Name
              </Table.Th>
              {!isCollapsed && (
                <Table.Th
                  style={{
                    width: '20%',
                  }}
                >
                  {isWebCall ? 'Email' : 'Phone'}
                </Table.Th>
              )}

              <Table.Th
                style={{
                  width: '20%',
                }}
              >
                {isWebCall ? 'Called At' : 'Last Called'}
              </Table.Th>
              <SortableHeader
                sorted={sort?.column === SortColumn.CALL_LENGTH_SEC}
                reversed={sort?.direction === 'ascending'}
                onSort={handleSort(SortColumn.CALL_LENGTH_SEC)}
                style={{
                  width: '15%',
                }}
              >
                Call Length
              </SortableHeader>
              {!isWebCall && (
                <Table.Th
                  style={{
                    width: '10%',
                  }}
                >
                  Calls
                </Table.Th>
              )}
              <SortableHeader
                sorted={sort?.column === SortColumn.OVERALL_GRADE}
                reversed={sort?.direction === 'ascending'}
                onSort={handleSort(SortColumn.OVERALL_GRADE)}
                style={{
                  width: '15%',
                }}
              >
                Score
              </SortableHeader>
              <SortableHeader
                sorted={sort?.column === SortColumn.COMPLETION_RATE}
                reversed={sort?.direction === 'ascending'}
                onSort={handleSort(SortColumn.COMPLETION_RATE)}
                style={{
                  width: '20%',
                }}
              >
                Completion Rate
              </SortableHeader>
              {!isCollapsed && !isWebCall && (
                <Table.Th
                  style={{
                    width: '20%',
                  }}
                >
                  SMS Status
                </Table.Th>
              )}

              <Table.Th
                style={{
                  width: '15%',
                }}
              >
                Call Status
              </Table.Th>
              {scheduleFollowUp && !isCollapsed && (
                <Table.Th
                  style={{
                    width: '20%',
                  }}
                >
                  Booked Meeting
                </Table.Th>
              )}

              <Table.Th
                style={{
                  width: '5%',
                  padding: '0',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center' /* Center horizontally */,
                    alignItems: 'center' /* Center vertically */,
                    height: '100%',
                    color: 'var(--salv-dark-6)',
                    marginRight: '5px',
                  }}
                  className='refreshIconContainer'
                >
                  <ActionIcon
                    variant='transparent'
                    className='refreshIconContainer'
                    onClick={() => {
                      setIsFetchingCalls(true);
                      setCandidates([]);
                      debouncedFetchCandidates(PAGINATION_AMOUNT, 0);
                    }}
                  >
                    <IconRefresh className='refreshIconContainer' size={16} />
                  </ActionIcon>
                </div>
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {candidates.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <Table.Tr
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(item.candidate.candidate_id);
                    }}
                    // onClick={() => toggleRow(item.full_name)}
                  >
                    <Table.Td>{item.candidate.full_name}</Table.Td>
                    {!isCollapsed && (
                      <Table.Td>
                        {isWebCall
                          ? item.candidate.email
                          : formatPhoneNumber(item.candidate.phone_number)}
                      </Table.Td>
                    )}
                    <Table.Td>{candidateToLastCalled(item)}</Table.Td>
                    <Table.Td>{candidateToRecentCallLength(item)}</Table.Td>
                    {!isWebCall && (
                      <Table.Td>{candidateToNumberOfCalls(item)}</Table.Td>
                    )}
                    <Table.Td>{candidateToOverallGrade(item)}</Table.Td>
                    <Table.Td>{candidateToCompletionRate(item)}</Table.Td>
                    {!isCollapsed && !isWebCall && (
                      <Table.Td>
                        <Badge autoContrast className='status-badge'>
                          {candidateToSmsStatus(item.candidate)}
                        </Badge>
                      </Table.Td>
                    )}

                    <Table.Td>
                      <Badge autoContrast className='status-badge'>
                        {candidateToCallStatus(item.candidate)}
                      </Badge>
                    </Table.Td>
                    {scheduleFollowUp && !isCollapsed && (
                      <Table.Td>
                        {candidateIdsNeedMeetingFetched.includes(
                          item.candidate.candidate_id
                        ) ? (
                          <Skeleton height={20} />
                        ) : (
                          formatMeetingTime(
                            meetingTimes[item.candidate.candidate_id] || null
                          )
                        )}
                      </Table.Td>
                    )}
                    <Table.Td>{}</Table.Td>
                  </Table.Tr>
                </React.Fragment>
              );
            })}
            {!isFetchingCalls && candidates && candidates?.length === 0 && (
              <tr>
                <td colSpan={12} style={{ minWidth: '100%' }}>
                  <Center p='lg'>No contacts</Center>
                </td>
              </tr>
            )}
            {isFetchingCalls && (
              <tr>
                <td colSpan={12} style={{ minWidth: '100%', width: '100%' }}>
                  <Center p='lg'>
                    <Loader size='sm' type='dots' />
                  </Center>
                </td>
              </tr>
            )}
          </Table.Tbody>
        </Table>
      </div>

      <Modal
        opened={transcriptOpened}
        onClose={closeTranscript}
        size='100%'
        // style={{ padding: '40px' }}
        styles={{
          header: {
            backgroundColor: 'transparent',
          },
        }}
      >
        {isFetchingTranscript ? (
          <Center p='lg'>
            <Loader size='sm' type='dots' />
          </Center>
        ) : transcriptError ? (
          <div
            style={{
              padding: '100px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Title c={'black'}>Failed to retrieve call details</Title>
          </div>
        ) : (
          <Transcript
            transcript={transcriptData.transcript}
            requirementGradesList={transcriptData.requirementGradesList}
            callId={transcriptData.callId}
            smsTranscript={transcriptData.smsTranscript}
            overallGrade={transcriptData.overallGrade}
            completionRate={transcriptData.questionCompletionRate}
            candidateName={transcriptData.candidateName}
            lastCalled={transcriptData.lastCalled}
            allCalls={transcriptData.allCalls}
            callComplete={transcriptData.callComplete}
            emails={transcriptData.emails}
            webCall={transcriptData.webCall}
          />
        )}
      </Modal>
    </div>
  );
};

export default CampaignDetailsPage;
