import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  ScrollArea,
  Text,
  Group,
  RingProgress,
  Tabs,
  Center,
  Loader,
  Button,
  Tooltip,
  Kbd,
  CloseButton,
  ActionIcon,
  Textarea,
  Avatar,
} from '@mantine/core';
import { formatToLocalTime } from 'utils/dateUtils';
import {
  IconCalendar,
  IconCalendarCheck,
  IconTrash,
} from '@tabler/icons-react';
import { convertUpperSnakeToTitle } from 'utils/formatUtils';
import { RequirementImportance } from 'pages/editorv2/types';
import env from 'env';
import { notifications } from '@mantine/notifications';
import { IconPhone } from '@tabler/icons-react';

import WebCallRecordingPlayer from './WebCallRecordingPlayer';
import PhoneCallRecordingPlayer from './PhoneCallRecordingPlayerV2';
import './Transcript.css';
import EmailHistory, { Email } from './EmailHistory';
import axios from '../../api/axiosConfig';
import CandidateFeedback from './CandidateFeedback';

/* ----------------------------------------------------------------
   Tabs Setup
----------------------------------------------------------------- */

const TabOptions = {
  CALL: 'Call',
  SMS: 'SMS',
  EMAIL: 'Email',
} as const;

type Tab = (typeof TabOptions)[keyof typeof TabOptions];
const isTab = (value: any): value is Tab => {
  return Object.values(TabOptions).includes(value);
};

export interface TranscriptLine {
  start_ts: number;
  role: string;
  content: string;
}

/* ----------------------------------------------------------------
   Transcript Props
----------------------------------------------------------------- */

interface TranscriptProps {
  transcript: string; // Phone call transcript in raw text form
  requirementGradesList: {
    question: string;
    answer: string;
    grade: string;
    importance: RequirementImportance;
    ts: number;
    title: string;
  }[];
  callId: string;
  overallGrade?: number;
  completionRate?: number;
  candidateName?: string;
  lastCalled?: string;
  testCall?: boolean;
  allCalls?: any[];
  callComplete?: boolean;
  emails?: Email[];
  emailsLoading?: boolean;
  webCall?: boolean;
  candidateId?: string;
  campaignId?: string;
  showNavigationShortcut?: boolean;
}

/* ----------------------------------------------------------------
   Existing parseSmsTranscript
----------------------------------------------------------------- */
interface Comment {
  comment_id: string;
  text: string;
  user_email: string;
  user_first_name: string;
  user_last_name: string;
  created_at: string;
  modified_at?: string;
}

const parseSmsTranscript = (smsTranscript: string | undefined) => {
  if (!smsTranscript) return [];
  if (typeof smsTranscript !== 'string') {
    console.error('Invalid type for text:', typeof smsTranscript);
    return [];
  }
  return smsTranscript
    .split('\n')
    .map((line) => {
      const [timestampStr, rest] = line.split('|');
      if (!timestampStr || !rest) {
        return null;
      }

      const timestamp = new Date(timestampStr);
      const [role, ...contentParts] = rest.split(':');
      return {
        start_ts: timestamp.getTime(),
        role: role.trim(),
        content: contentParts.join(':').trim(),
      };
    })
    .filter(Boolean) // remove null entries
    .filter((item): item is TranscriptLine => !!item);
};

/* ----------------------------------------------------------------
   parseCallHistory (for merging into SMS)
----------------------------------------------------------------- */

const parseCallHistory = (callHistory: any[] = []): TranscriptLine[] => {
  return callHistory
    .map((call) => {
      if (
        !call?.last_updated ||
        call.call_status === 'PENDING' ||
        call.call_status === 'CANCELLED'
      )
        return null;

      const durationSec = call.call_length_sec || 0;
      const minutes = Math.floor(durationSec / 60);
      const seconds = durationSec % 60;

      // Create a Date from last_updated and subtract the duration
      const adjustedDate = new Date(call.last_updated + 'Z'); // needed because was a naiive time before
      adjustedDate.setSeconds(adjustedDate.getSeconds() - durationSec);
      // Hack to add additional offset for incomplete calls to account for the fact that postprocessing usually takes longer than sending the follow-up texts
      const additionalOffset = durationSec > 30 ? 0 : 1 * 60 * 1000;
      return {
        start_ts:
          adjustedDate.getTime() - additionalOffset - durationSec * 1000, // offset hack
        role: 'Call',
        // Pass the adjusted time to formatToLocalTime
        content:
          `${convertUpperSnakeToTitle(call.call_initiated_by)} ` +
          `${formatToLocalTime(adjustedDate, true)} ` +
          `(${minutes}m ${seconds}s) [${convertUpperSnakeToTitle(call.call_status)}]`,
      };
    })
    .filter((item): item is TranscriptLine => !!item); // remove nulls
};

/* ----------------------------------------------------------------
   BoldTranscript - now can handle either:
     - an array of messages, OR
     - a raw string (like phone transcript)
----------------------------------------------------------------- */

const BoldTranscript = ({
  transcript,
  sms,
  onTimestampClick,
  clickable,
}: {
  transcript: any[] | string;
  sms: boolean;
  onTimestampClick: (ts: number | null) => void;
  clickable: boolean;
}) => {
  const escapeAngleBrackets = (text: string) =>
    text.replace(/</g, '&lt;').replace(/>/g, '&gt;');

  const highlightGrayText = (text: string) => {
    let updatedText = escapeAngleBrackets(text);
    updatedText = updatedText.replace(
      /(&lt;.*?&gt;|~(.*?)~)/g,
      '<span style="color:gray">$1</span>'
    );
    return updatedText;
  };

  const formatTimestampSMS = (timestampMs: number) => {
    const date = new Date(timestampMs);
    return date.toLocaleString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  const formatTimestamp = (timestamp: number) => {
    if (timestamp === null || timestamp === undefined) {
      return null;
    }
    const minutes = Math.floor(timestamp / 60);
    const seconds = Math.floor(timestamp % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  // 1) If transcript is a string, just render it like old phone transcript
  if (typeof transcript === 'string') {
    return (
      <Text
        style={{ fontSize: '14px' }}
        dangerouslySetInnerHTML={{
          __html: highlightGrayText(transcript),
        }}
      />
    );
  }

  // 2) Otherwise, transcript is an array of messages
  const processTranscriptArray = (messages: any[]) => {
    if (!Array.isArray(messages)) {
      console.error('Invalid transcript data:', messages);
      return <Text>No transcript available</Text>;
    }

    const renderMessageBlock = (message: any, index: number) => {
      if (message.role === 'Call') {
        // Render Call entries differently
        return (
          <Group
            key={index}
            style={{
              marginTop: index === 0 ? 0 : 12,
              alignItems: 'center',
              justifyContent: 'center',
              opacity: 0.9,
              width: 'fit-content',
              gap: '8px',
              border: '1px solid var(--salv-dark-2)',
              borderRadius: '6px',
              marginLeft: 'auto',
              marginRight: 'auto',
              padding: '10px 16px',
              whiteSpace: 'nowrap',
              flexWrap: 'nowrap',
              overflow: 'hidden',
            }}
            onClick={() => onTimestampClick(message.start_ts ?? null)}
            className={clickable ? 'clickable' : ''}
          >
            <IconPhone size={16} /> {/* Phone icon */}
            <Text style={{ fontSize: '11px', fontWeight: '500' }}>
              {message.content}
            </Text>
          </Group>
        );
      } else {
        // Render SMS or other message types as usual
        return (
          <Group
            key={index}
            style={{
              marginTop: index === 0 ? 0 : 12,
              alignItems: 'flex-start',
              width: '100%',
            }}
            onClick={() => onTimestampClick(message.start_ts ?? null)}
            className={
              clickable &&
              message.start_ts !== null &&
              message.start_ts !== undefined
                ? 'clickable'
                : ''
            }
          >
            <div style={{ marginLeft: 0, width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: sms ? 'space-between' : 'flex-start',
                  width: '100%',
                }}
              >
                {!sms && message.start_ts !== null && (
                  <Text size='xs' style={{ color: 'gray', marginRight: 8 }}>
                    {formatTimestamp(message.start_ts)}
                  </Text>
                )}
                <Text
                  size='sm'
                  fw={700}
                  style={{
                    color:
                      message.role === 'Candidate'
                        ? 'var(--mantine-color-blue-9)'
                        : '#222324',
                  }}
                >
                  {message.role}
                </Text>
                {sms && message.start_ts !== null && (
                  <Text size='xs' style={{ color: 'gray', marginRight: 8 }}>
                    {formatTimestampSMS(message.start_ts)}
                  </Text>
                )}
              </div>
              <Text
                style={{ paddingLeft: '4px', fontSize: '14px' }}
                dangerouslySetInnerHTML={{
                  __html: highlightGrayText(message.content.trim()),
                }}
              />
            </div>
          </Group>
        );
      }
    };

    return messages.map((msg, i) => renderMessageBlock(msg, i));
  };

  return <div>{processTranscriptArray(transcript)}</div>;
};

/* ----------------------------------------------------------------
   Main Transcript Component
----------------------------------------------------------------- */

const Transcript: React.FC<TranscriptProps> = ({
  transcript, // raw phone transcript (string)
  requirementGradesList,
  callId,
  overallGrade,
  completionRate,
  candidateName,
  lastCalled,
  testCall,
  allCalls,
  callComplete,
  emails,
  emailsLoading,
  webCall,
  candidateId,
  campaignId,
  showNavigationShortcut,
}) => {
  const [activeTab, setActiveTab] = useState<Tab>(TabOptions.CALL);

  const email = localStorage.getItem('email') || '';
  const userId = localStorage.getItem('userId') || '';
  const isInternal = email.includes('salv.ai');
  const calApiKey = localStorage.getItem('calAPIKey');
  const [sentSchedule, setSentSchedule] = useState(false);

  const [isNavigationShortcutDissmissed, setIsNavigationShortcutDissmissed] =
    useState(() => {
      return localStorage.getItem('dismiss_navigation_shortcut') === 'true';
    });

  const [seekTimestamp, setSeekTimestamp] = useState<number | null>(null);
  const onTimestampClick = (timestamp: number | null) => {
    if (timestamp !== null) {
      setSeekTimestamp(timestamp);
    }
  };

  const dismissNavigationShortcut = () => {
    localStorage.setItem('dismiss_navigation_shortcut', 'true');
    setIsNavigationShortcutDissmissed(true);
  };

  /* ---------------------------
     QA / Results Column
  ----------------------------*/
  const renderResultsColumn = () => {
    if (!callId) {
      return (
        <div className='qa-column'>
          <h4
            className='candidate-name'
            style={{ marginTop: 0, marginBottom: 0 }}
          >
            {candidateName}
          </h4>
          <h4 style={{ margin: 'auto' }}> No Screening Results</h4>
        </div>
      );
    }
    return (
      <div className='qa-column'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <h4
              className='candidate-name'
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              {candidateName}
            </h4>
            {!testCall && campaignId && candidateId && (
              <CandidateFeedback
                campaignId={campaignId}
                candidateId={candidateId}
              />
            )}
          </div>
          {!!calApiKey &&
            !!completionRate &&
            completionRate > 0 &&
            (email.includes('mason@salv.ai') ||
              email.includes('john@salv.ai')) && (
              <div
                style={{
                  zIndex: 1000,
                  position: 'relative',
                  marginLeft: '6px',
                }}
              >
                {!sentSchedule ? (
                  <Tooltip
                    openDelay={500}
                    label='AI will reach out over SMS to schedule next interview with the candidate'
                  >
                    <Button
                      size='xs'
                      variant='subtle'
                      onClick={(e) => {
                        e.stopPropagation();
                        setSentSchedule(true);
                      }}
                      leftSection={<IconCalendar size={18} />}
                    >
                      Schedule Next Interview
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    size='xs'
                    variant='subtle'
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    disabled={true}
                    leftSection={<IconCalendarCheck />}
                  >
                    Follow Up Requested
                  </Button>
                )}
              </div>
            )}
        </div>

        <div
          style={{
            display: 'flex',
            gap: '15%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <RingProgress
              size={65}
              thickness={6}
              roundCaps
              sections={[
                {
                  value: (completionRate || 0) * 100,
                  color: 'var(--mantine-color-blue-4)',
                },
              ]}
              label={
                <Text c='blue' fw={700} ta='center' size='xs'>
                  {`${((completionRate || 0) * 100).toFixed(0)}%`}
                </Text>
              }
            />
            <div style={{ fontWeight: '500' }}>Completion Rate</div>
          </div>
          <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
            <RingProgress
              size={65}
              thickness={6}
              roundCaps
              sections={[
                {
                  value: overallGrade || 0,
                  color: 'var(--mantine-color-blue-4)',
                },
              ]}
              label={
                <Text c='blue' fw={700} ta='center' size='xs'>
                  {`${(overallGrade || 0).toFixed(0)}%`}
                </Text>
              }
            />
            <div style={{ fontWeight: '500' }}>Grade</div>
          </div>
        </div>
        <ScrollArea className='scrollable-content'>
          <div className='scroll-fade-top'></div>
          <div className='inside'>
            {requirementGradesList.map(
              ({ question, answer, grade, ts, title }, index) => (
                <div
                  key={index}
                  className={'qa-item' + (ts ? ' clickable' : '')}
                  onClick={() => {
                    if (ts) {
                      onTimestampClick(ts);
                    }
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <div className='grade'>{grade}</div>
                    <div className='question'>{title || question}</div>
                  </div>
                  <div className='answer'>{answer}</div>
                </div>
              )
            )}
          </div>
          {renderComments()}
          <div className='scroll-fade-bottom'></div>
        </ScrollArea>
      </div>
    );
  };

  /* ---------------------------
     RETURN
  ----------------------------*/

  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState('');
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [isSavingComment, setIsSavingComment] = useState(false);

  const fetchComments = useCallback(async () => {
    setIsLoadingComments(true);
    try {
      const response = await axios.get(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/comments/${campaignId}`
      );
      setComments(response.data || []);
    } catch (error) {
      console.error('Error fetching comments:', error);
    } finally {
      setIsLoadingComments(false);
    }
  }, [candidateId, campaignId]);

  const handleSaveComment = async () => {
    if (!newComment.trim()) return;

    setIsSavingComment(true);
    try {
      await axios.post(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/comments/${campaignId}`,
        {
          text: newComment,
          userId: userId,
        }
      );
      await fetchComments();
      setNewComment('');
    } catch (error) {
      console.error('Error saving comment:', error);
    } finally {
      setIsSavingComment(false);
    }
  };

  const handleDeleteComment = async (commentId: string) => {
    try {
      await axios.delete(
        `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/comments/${campaignId}/${commentId}`
      );
      await fetchComments();
    } catch (error) {
      console.error('Error deleting comment:', error);
      notifications.show({
        title: 'Error',
        message: 'Failed to delete comment',
        color: 'red',
      });
    }
  };

  useEffect(() => {
    fetchComments();
  }, [candidateId, campaignId, fetchComments]);

  const renderComments = () => (
    <div className='comments-section'>
      <h4 style={{ marginTop: '0px' }}>Comments</h4>
      <div className='comments-list'>
        {isLoadingComments ? (
          <div>Loading notes...</div>
        ) : (
          comments.map((comment) => (
            <div key={comment.comment_id} className='comment'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>{comment.text}</p>
                <ActionIcon
                  variant='subtle'
                  color='red'
                  onClick={() => handleDeleteComment(comment.comment_id)}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </div>
              <small style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  variant='filled'
                  color='var(--mantine-color-blue-3)'
                  size='sm'
                  name={comment.user_first_name + ' ' + comment.user_last_name}
                  style={{ margin: '6px', marginLeft: '0px' }}
                />
                {comment.user_first_name + ' ' + comment.user_last_name} on{' '}
                {new Date(comment.created_at).toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                })}
              </small>
            </div>
          ))
        )}
      </div>
      <div className='new-comment'>
        <Textarea
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder='Add a comment...'
        ></Textarea>
        <div style={{ display: 'flex' }}>
          <Button
            size='xs'
            onClick={handleSaveComment}
            disabled={isSavingComment || !newComment.trim()}
            style={{ padding: '0px 30px' }}
            color='var(--mantine-color-blue-6)'
          >
            {isSavingComment ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <Box className='transcript-container'>
      <Tabs
        variant='pills'
        value={activeTab}
        onChange={(value) => {
          if (isTab(value)) setActiveTab(value);
        }}
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          zIndex: 10001,
        }}
      >
        <div
          style={{
            flex: 1,
            overflow: testCall ? 'hidden' : 'unset',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '6px',
            }}
          >
            <Tabs.List>
              <Tabs.Tab
                value={TabOptions.CALL}
                classNames={{
                  tab: 'header-tab',
                }}
              >
                {!webCall ? 'Phone Call' : 'Video Call'}
              </Tabs.Tab>
              {!testCall && !webCall && (
                <Tabs.Tab
                  value={TabOptions.SMS}
                  classNames={{
                    tab: 'header-tab',
                  }}
                >
                  SMS
                </Tabs.Tab>
              )}
              {isInternal && !webCall && (
                <Tabs.Tab
                  value={TabOptions.EMAIL}
                  classNames={{
                    tab: 'header-tab',
                  }}
                >
                  Email
                </Tabs.Tab>
              )}
            </Tabs.List>
          </div>

          {/* ---------- SMS Tab Panel ---------- */}
          <Tabs.Panel
            value={TabOptions.SMS}
            style={{ height: 'calc(100vh - 90px)', padding: 0, margin: 0 }}
          >
            <SMSTab
              candidateId={candidateId}
              campaignId={campaignId}
              allCalls={allCalls}
            />
          </Tabs.Panel>

          {/* ---------- CALL Tab Panel ---------- */}
          <Tabs.Panel
            value={TabOptions.CALL}
            style={{ height: 'calc(100% - 90px)', padding: 0, margin: 0 }}
          >
            {!allCalls?.length && !testCall && (
              <div className='transcript-column'>
                <h4 style={{ margin: 'auto' }}> No Call Results</h4>
              </div>
            )}
            {!!allCalls?.length && !callComplete && !testCall && (
              <div className='transcript-column calls-container'>
                <h3>Call History</h3>
                <ScrollArea className='scrollable-content'>
                  {allCalls.map((c, idx) => (
                    <div
                      key={idx}
                      style={{
                        display: 'flex',
                        padding: '14px',
                        gap: '8px',
                        fontSize: '14px',
                        justifyContent: 'space-between',
                        marginRight: '25%',
                        border: '1px solid var(--salv-dark-1)',
                        borderRadius: '8px',
                        marginBottom: '10px',
                      }}
                    >
                      <div style={{ fontWeight: '600', fontSize: '16px' }}>
                        {convertUpperSnakeToTitle(c.call_status)}
                      </div>
                      <div style={{ opacity: 0.7 }}>
                        {formatToLocalTime(c.last_updated, true)}
                      </div>
                    </div>
                  ))}
                </ScrollArea>
              </div>
            )}
            {!!callId && callComplete && !webCall && (
              <div>
                {lastCalled && (
                  <div
                    style={{
                      padding: '8px',
                      fontSize: '12px',
                      marginTop: '6px',
                    }}
                  >
                    {`Call from ${formatToLocalTime(lastCalled, true)}`}
                  </div>
                )}
                <PhoneCallRecordingPlayer
                  callId={callId}
                  seekTimestamp={seekTimestamp}
                  webAudio={false}
                />
              </div>
            )}

            {callComplete && (
              <div
                className={`transcript-column ${webCall ? 'video' : 'phone'}-transcript-column`}
              >
                <ScrollArea className='scrollable-content'>
                  {!!callId && callComplete && webCall && (
                    <div>
                      {lastCalled && (
                        <div
                          style={{
                            padding: '8px',
                            fontSize: '12px',
                            marginTop: '6px',
                          }}
                        >
                          {`Call from ${formatToLocalTime(lastCalled, true)}`}
                        </div>
                      )}
                      <WebCallRecordingPlayer
                        callId={callId}
                        seekTimestamp={seekTimestamp}
                        testCall={testCall}
                      />
                    </div>
                  )}
                  <div className='inside transcript-box'>
                    {/* Here we pass the raw string transcript for phone calls */}
                    <BoldTranscript
                      transcript={transcript}
                      sms={false}
                      onTimestampClick={onTimestampClick}
                      clickable={true}
                    />
                  </div>
                  <div className='scroll-fade-bottom'></div>
                </ScrollArea>
              </div>
            )}
          </Tabs.Panel>

          {/* ---------- EMAIL Tab Panel ---------- */}
          <Tabs.Panel
            value={TabOptions.EMAIL}
            style={{ height: 'calc(100% - 90px)', padding: 0, margin: 0 }}
          >
            <EmailHistory emails={emails} loading={emailsLoading} />
          </Tabs.Panel>
        </div>
      </Tabs>

      {/* Results Column */}
      {renderResultsColumn()}

      {/* Navigation Shortcut Prompt */}
      {showNavigationShortcut && !isNavigationShortcutDissmissed && (
        <Box className='keyboard-shortcut-container'>
          <div className='keyboard-shortcut-content'>
            Use
            <Kbd style={{ margin: '0px 3px 0px 6px' }}>&uarr;</Kbd>
            <Kbd style={{ margin: '0px 6px 0px 3px' }}>&darr;</Kbd>
            to navigate
          </div>
          <CloseButton onClick={dismissNavigationShortcut} />
        </Box>
      )}
    </Box>
  );
};

/* ----------------------------------------------------------------
   SMSTab - merges SMS + Call history, displays as one transcript
----------------------------------------------------------------- */

const SMSTab = ({
  candidateId,
  campaignId,
  allCalls,
}: {
  candidateId?: string;
  campaignId?: string;
  allCalls?: any[];
}) => {
  const [transcriptSMSError, setTranscriptSMSError] = useState(false);
  const [isFetchingSMSTranscript, setIsFetchingSMSTranscript] = useState(false);

  // We'll store both pieces in state
  const [smsTranscript, setSmsTranscript] = useState('');

  useEffect(() => {
    if (!candidateId || !campaignId) return;

    const fetchSMSTranscriptData = async () => {
      setIsFetchingSMSTranscript(true);
      setTranscriptSMSError(false);

      try {
        const response = await axios.get(
          `${env.REACT_APP_SERVER_URL}/candidate/${candidateId}/sms_details/${campaignId}`
        );
        const { sms_transcript } = response.data;

        setSmsTranscript(sms_transcript || '');
      } catch (error) {
        setTranscriptSMSError(true);
        console.error('Error fetching sms/call data:', error);
        notifications.show({
          title: 'There was an error retrieving sms transcript details',
          message: error instanceof Error ? error.message : '',
          color: 'red',
        });
      } finally {
        setIsFetchingSMSTranscript(false);
      }
    };

    fetchSMSTranscriptData();
  }, [campaignId, candidateId]);

  if (isFetchingSMSTranscript) {
    return (
      <Center p='lg'>
        <Loader size='sm' type='dots' />
      </Center>
    );
  }

  if (transcriptSMSError) {
    return (
      <Center style={{ textAlign: 'center', height: '100%' }}>
        <h5>Failed to fetch SMS Transcript/Call History</h5>
      </Center>
    );
  }

  // Parse the SMS lines + calls into the same shape
  const parsedSms = parseSmsTranscript(smsTranscript);
  const parsedCalls = parseCallHistory(allCalls);

  // Merge & sort
  const combined = [...parsedSms, ...parsedCalls].sort(
    (a: TranscriptLine, b: TranscriptLine) => a.start_ts - b.start_ts
  );

  if (!combined.length) {
    return (
      <Center style={{ textAlign: 'center', height: '100%' }}>
        <h5>No SMS or Call History Available</h5>
      </Center>
    );
  }

  return (
    <div className='transcript-column'>
      <ScrollArea className='scrollable-content'>
        <div className='scroll-fade-top'></div>
        <div className='inside'>
          <BoldTranscript
            transcript={combined}
            sms={true}
            onTimestampClick={() => {}}
            clickable={false}
          />
        </div>
        <div className='scroll-fade-bottom'></div>
      </ScrollArea>
    </div>
  );
};

export default Transcript;
